body
{
	background-image:url(images/background.jpg);
	background-position:center;
	background-size:cover;
	background-repeat:no-repeat;
	background-attachment:fixed;
}
.carousel-item, .image-box
{
	border:10px solid white;
}
.carousel-item img
{
	width:100%;
}

.theme-link:hover
{
	text-decoration:none;
}
.theme-box
{
	background-color:rgba(250, 104, 0, 0.8);
	color:white;
}
.theme-box:hover
{
	background-color:rgba(255, 255, 255, 0.8);
	color:black;
}

.theme-box2
{
	background-color:rgba(250, 104, 0, 1);
	color:white;
	margin-left:-30px;
	border-radius: 20px 0px 0px 20px;
}



.subtitle
{
	font-size:36px;
}

.subtext
{
	color:#556E6C;
	font-size:13px;
}

.normaltext
{
	color:#556E6C;
}

.white-back
{
	background-color:rgba(255,255,255,0.9)
}

.theme-font
{
	font-size:24px;
}

.box
{
	font-size:15px;
}

.box-image
{
	background-position:center top;
	background-size:contain;
	background-repeat:no-repeat;
	padding:6px 15px;
	color:rgba(250, 104, 0, 1);
	font-weight:bold;
	font-size:13px;
}

.terma b
{
	font-size:18px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



/* HTML marker styles */
.price-tag {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}





.bottom-arrow {
    content:'';
    position: relative;
    top: 15px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px rgba(196, 115, 0, 0.7);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

@keyframes bounce { 
	from { 
		margin-top: -55px; 
	} 
	to { 
		margin-top: -65px; 
	}
}