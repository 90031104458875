.nav-pills .nav-link
{
	border-radius:20px !important;
	background-color:white !important;
	padding:5px 30px !important;
	color:black !important;
	font-size:21px !important;
	margin:0px 5px !important;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover
{
	background-color: #ffd769 !important;
	color:black !important;
}

.nav.nav-pills{
	margin:auto !important;
}
@media only screen and (max-width: 767px) {
  .nav-pills .nav-link{
    background-color: transparent !important;
	color:white !important;
	width:100% !important;
	border-radius:0px !important;
	
  }
  .nav-item
  {
	  width:100% !important;
  }
  .navbar
  {
	  background-color:#343a40 !important;
  }
}